@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 700;
  src: local('Spoqa Han Sans Bold'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansBold.woff2') format('woff2'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansBold.woff') format('woff'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 400;
  src: local('Spoqa Han Sans Regular'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansRegular.woff2') format('woff2'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansRegular.woff') format('woff'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 300;
  src: local('Spoqa Han Sans Light'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansLight.woff2') format('woff2'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansLight.woff') format('woff'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 100;
  src: local('Spoqa Han Sans Thin'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansThin.woff2') format('woff2'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansThin.woff') format('woff'),
    url('//static.dogmate.co.kr/fonts/SpoqaHanSans/SpoqaHanSansThin.ttf') format('truetype');
}

body {
  margin: 0;
  max-width: 640px;
  width: 100vw;
  font-family: 'Spoqa Han Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  outline: 0;
}

#root {
  display: flex;
  justify-content: center;
}
